<template>
  <section class="section has-background-white-bis transition">
    <div class="container">
      <SectionHeader
        title="Choose vehicles to analyse"
        title-icon="fal fa-chart-network"
      />
      <div class="content">
        <p>Select a make and at least one model or keyword</p>
      </div>
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <div class="field">
            <label
              for="make"
              class="label has-text-grey-dark has-text-weight-normal"
              >Make</label
            >
            <input
              ref="make"
              type="text"
              placeholder="Ferrari"
              class="input"
              @keydown.enter="search"
              v-model="form.make"
            />
          </div>
          <div class="field">
            <label
              for="model"
              class="label has-text-grey-dark has-text-weight-normal"
              >Model or keywords</label
            >
            <search-select
              ref="searchSelect"
              v-bind="{ preset, placeHolder: 'F40' }"
              @input="value = $event"
              @change="addModels"
            />
          </div>
        </div>
        <div class="column is-narrow">
          <label
            for="minYear"
            class="label has-text-grey-dark has-text-weight-normal"
            >Year range</label
          >
          <div class="field has-addons">
            <div class="control is-expanded">
              <input
                type="number"
                placeholder="1886"
                :min="1886"
                :max="currentYear"
                class="input"
                @keydown.enter="search"
                v-model="form.minYear"
              />
            </div>
            <div class="control is-expanded">
              <input
                type="number"
                :placeholder="currentYear"
                :min="minMaxYear"
                :max="currentYear"
                class="input"
                @keydown.enter="search"
                v-model="form.maxYear"
              />
            </div>
          </div>
          <label
            for="minMileage"
            class="label has-text-grey-dark has-text-weight-normal"
            >Mileage range</label
          >
          <div class="field has-addons">
            <div class="control">
              <input
                type="number"
                :min="0"
                placeholder="0"
                :step="10000"
                class="input"
                @keydown.enter="search"
                v-model="form.minMileage"
              />
            </div>
            <div class="control">
              <input
                type="number"
                placeholder="10000"
                :min="minMaxMileage"
                :step="10000"
                class="input"
                @keydown.enter="search"
                v-model="form.maxMileage"
              />
            </div>
          </div>
          <div class="buttons is-right">
            <button
              class="button is-danger is-light is-rounded"
              @click="resetForm"
              :disabled="$wait.is('search')"
            >
              <span class="icon">
                <i class="fad fa-trash-alt"></i>
              </span>
              <span>
                Clear
              </span>
            </button>
            <button
              class="button is-info is-rounded"
              @click="search"
              :class="{ 'is-loading': $wait.is('search') }"
              :disabled="$wait.is('search')"
            >
              <span class="icon">
                <i class="fad fa-search"></i>
              </span>
              <span>
                Search
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { clone, cloneDeep } from 'lodash'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'ExplorerSearchForm',
  components: {
    SectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader'),
    searchSelect: () =>
      import('core/modules/search-select/components/SearchSelect')
  },
  data: () => ({
    form: {
      make: '',
      models: [],
      minYear: null,
      maxYear: null,
      minMileage: null,
      maxMileage: null
    },
    value: '',
    timeout: null
  }),
  watch: {
    'form.minYear'() {
      if (parseInt(this.form.minYear) >= parseInt(this.form.maxYear)) {
        this.form.maxYear = parseInt(this.form.minYear) + 1
      }
    },
    'form.minMileage'() {
      if (parseInt(this.form.minMileage) >= parseInt(this.form.maxMileage)) {
        this.form.maxMileage = parseInt(this.form.minMileage) + 10000
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.make.focus()
    })
    this.form.make = this.storedForm.make
    this.form.models = this.storedForm.models
  },
  destroyed() {
    if (this.timeout) clearTimeout(this.timeout)
  },
  computed: {
    ...mapGetters({ storedForm: 'explorer/form' }),
    preset() {
      return clone(this.storedForm.models)
    },
    hasCompletedForm() {
      return !!(
        this.form.make.length &&
        ((this.form.models && this.form.models.length) || this.value)
      )
    },
    currentYear: () => new Date().getFullYear(),
    minMaxYear() {
      return this.form.minYear ? parseInt(this.form.minYear) + 1 : 1886
    },
    minMaxMileage() {
      return this.form.minMileage ? parseInt(this.form.minMileage) + 10000 : 0
    }
  },
  methods: {
    ...mapActions('explorer', ['getGroups']),
    ...mapMutations('explorer', ['setListings', 'setForm']),
    addModels(event) {
      if (event) {
        this.form.models = event
        this.search()
      }
    },
    resetForm() {
      const form = {
        make: '',
        models: [],
        minYear: null,
        maxYear: null,
        minMileage: null,
        maxMileage: null
      }
      this.$refs.searchSelect.clearAll()
      this.setForm({ ...form })
      this.form = { ...form }
      this.value = ''
    },
    async search() {
      const { hasCompletedForm, value, form } = this
      if (hasCompletedForm) {
        this.$wait.start('search')
        this.timeout = setTimeout(() => this.$emit('toggleTimeout', true), 7000)

        if (value && !form.models.length) form.models.push(value)

        try {
          this.$mxp.track('explorer_group_search', { form })
          await this.getGroups(cloneDeep(form))
        } catch (err) {
          this.$mxp.track('explorer_group_search_failed', { err })
          const extra =
            err.code === 'ECONNABORTED' ? ', try narrowing your search' : ''
          this.$notify('Unable to fetch group data' + extra)
        } finally {
          this.$emit('toggleTimeout', false)
          clearTimeout(this.timeout)
          this.$wait.end('search')
        }
      }
    }
  }
}
</script>
